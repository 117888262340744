export class DataCustomerClass {

    private fromAPI : string;
    private email : string;
    private name : string;
    private password : string;

    constructor(email : string, name : string, from : string){
        this.fromAPI = from;
        this.email = email;
        this.name = name;
        this.password = "mySuperPassword";
    }
}
