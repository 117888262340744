import { DataCustomerClass } from "./data-customer-class.model";

export class CreateCustomerClass {

    private class: string;
    private action: string;
    private data :  DataCustomerClass;

    constructor(data : DataCustomerClass){

        this.class = "PUT";
        this.action = "webusers-eshop";
        this.data = data;

    }

}
