<!-- Logo centrado -->
<div class="center-logo">
    <img style="width: 40%;" src="../assets/Images/logo-fm.png" alt="Logo">
  </div>

  <!-- Primer bloque de 2 columnas -->
  <div class="container">
  <div class="box">
    <h2 class="title"><span>1</span>Selección del plan</h2>
    <div class="box-one">
    <div class="left-column">
      <!-- Formulario -->
      <mat-card class="content">
        <mat-card-content>
          <mat-form-field  class="form-fild"> 
            <mat-label>Correo del usuario</mat-label>
            <input [(ngModel)]="email" type="email" matInput   placeholder="example@gmail.com">
          </mat-form-field>
  
          <mat-form-field class="form-fild">
            <mat-label>Planes disponibles</mat-label>
            <mat-select [(value)]="selectedPlan">
              <mat-option value="essential">WineBox</mat-option>
              <mat-option value="signature">Club de Vinos</mat-option>
            </mat-select>
          </mat-form-field> 
          <div *ngIf="selectedPlan === 'essential'">

            <mat-form-field  class="form-fild">
              <mat-label>Tipo de Suscripción</mat-label>
              <mat-select [(value)]="essentialType" >
                
                <mat-option value="mensual">Mensual - ${{ plans[0].feeCost_plan }}</mat-option>
                <mat-option value="pagounico">Pago Único Por 3 Meses - ${{ plans[5].feeCost_plan }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="essentialType === 'mensual'" class="form-fild">
              <mat-label>Seleccione una opción</mat-label>
              <mat-select [(value)]="selected" >
                <!-- Opciones para el tipo de plan Essential -->
                <mat-option value="3">White & Rosé</mat-option>
                <mat-option value="0">Only Reds</mat-option>
                <mat-option value="4">Mix</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="essentialType === 'pagounico'" class="form-fild">
              <mat-label>Seleccione una opción</mat-label>
              <mat-select [(value)]="selected" >
                <!-- Opciones para el tipo de plan Essential -->
                <mat-option value="0">White & Rosé</mat-option>
                <mat-option value="1">Only Reds</mat-option>
                <mat-option value="2">Mix</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="selectedPlan === 'signature'">
            <mat-form-field class="form-fild">
              <mat-label>Tipo de Suscripción</mat-label>
              <mat-select [(value)]="selected" >
                <!-- Opciones para el tipo de plan Essential -->
                <mat-option value="1">Bimestral Mensual - ${{ plans[1].feeCost_plan }}</mat-option>
                <mat-option value="2">Pago Único Anual - ${{ plans[2].feeCost_plan }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  
    <div class="right-column">
      <!-- Imágenes con borde negro -->
        <div class="image-container">
          <div class="box-image box-image-esential" [ngClass]="{ 'border': selectedPlan === 'essential' }">
            <img class="logos" src="../assets/Images/Logo_WBox.png" alt="Essential">
            <img src="../assets/Images/esencial_box.webp" alt="Essential">
          </div>
          <div class="box-image box-image-signature" [ngClass]="{ 'border': selectedPlan === 'signature' }">
            <img class="logos" src="../assets/Images/logo_CVinos.png" alt="Signature">
            <img src="../assets/Images/signature_back.webp" alt="Signature">
          </div>
        </div>

       
      </div>
    </div>
  </div>
    <div class="box">
      <h2 class="title"><span>2</span>Dirección de entrega del cliente</h2>
      <div class="box-two">
        <div class="input-grid">
     
        <mat-form-field class="form-fild"> 
          <!-- <input matInput placeholder="Provincia"> -->
          <mat-label>Provincia</mat-label>
          <mat-select (selectionChange)="onProvinceChange()" [(value)]="province">
            <mat-option *ngFor="let p of provinces" [value]="p" >{{p.name}} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form-fild"> 
          <mat-label>Corregimiento</mat-label>
          <mat-select [(value)]="corregimiento">
            <mat-option *ngFor="let p of corregimientos" [value]="p" >{{p.name}} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form-fild"> 
          <input [(ngModel)]="phone" type="number" matInput placeholder="Teléfono">
        </mat-form-field></div>
        <mat-form-field class="form-fild full-width"> 
          <input [(ngModel)]="address" matInput placeholder="Dirección">
        </mat-form-field>
        
      </div>
    </div>
    <div class="box">
      <h2 class="title"><span>3</span>Tarjeta de Credito</h2><img style="height: 30px;" src="../assets/Images/Master-visa.png" alt="visa">
      <div class="box-three">
        <div class="two_column">
        <mat-form-field class="form-fild"> 
          <input [(ngModel)]="name" matInput placeholder="Nombre del Titular">
        </mat-form-field>
        <mat-form-field class="form-field">
          <input [(ngModel)]="number"  matInput type="number" placeholder="Número de tarjeta">
        </mat-form-field>
        </div>
        <div class="three-column">
        <mat-form-field class="form-field">
          <mat-label>Mes de expiración</mat-label>
          <mat-select [(ngModel)]="month" >
            <mat-option value="01">Enero</mat-option>
            <mat-option value="02">Febrero</mat-option>
            <mat-option value="03">Marzo</mat-option>
            <mat-option value="04">Abril</mat-option>
            <mat-option value="05">Mayo</mat-option>
            <mat-option value="06">Junio</mat-option>
            <mat-option value="07">Julio</mat-option>
            <mat-option value="08">Agosto</mat-option>
            <mat-option value="09">Septiembre</mat-option>
            <mat-option value="10">Octubre</mat-option>
            <mat-option value="11">Noviembre</mat-option>
            <mat-option value="12">Diciembre</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form-field">
          <mat-label>Año de expiración</mat-label>
          <mat-select [(ngModel)]="year">
            <mat-option value="23">2023</mat-option>
            <mat-option value="24">2024</mat-option>
            <mat-option value="25">2025</mat-option>
            <mat-option value="26">2026</mat-option>
            <mat-option value="27">2027</mat-option>
            <mat-option value="28">2028</mat-option>
            <mat-option value="29">2029</mat-option>
            <mat-option value="30">2030</mat-option>
            <mat-option value="31">2031</mat-option>
            <mat-option value="32">2032</mat-option>
            <mat-option value="33">2033</mat-option>
            <mat-option value="34">2034</mat-option>
            <mat-option value="35">2035</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form-fild"> 
          <input [(ngModel)]="cvv" type="password" maxlength="3"  matInput placeholder="CVV">
        </mat-form-field>
        
      </div>
    </div>
   
  </div>
  <button  (click)="send()" class="Suscribir">Suscribir</button>
  </div>

 
  