import { Component, OnInit } from '@angular/core';
import { CreateCustomerClass } from '../class/create-customer-class.model';
import { DataCustomerClass } from '../class/data-customer-class.model';
import { FacPopupComponent } from '../service/fac-popup/fac-popup.component';
import { LoaderService } from '../service/loader.service';
import { NotificationService } from '../service/notification.service';
import { WebService } from '../service/web.service';
import { DomSanitizer} from '@angular/platform-browser';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit  {
  selectedPlan: string | undefined;
  essentialType: string | undefined;
  public plans : Array<any> = [] ;
  public provinces : Array<any> = [] ;
  public corregimientos : Array<any> = [] ;
  province : any;
  corregimiento : any;
  email = '';
  phone = '';
  address = '';
  name = '';
  number = '';
  year = '';
  month = '';
  cvv = '';
  show = false;
  selected : any = -1;
  service : any;
  partner : any;
  id_3dshasplan : any;

  constructor(private web : WebService, private notification : NotificationService, private loader : LoaderService,private dialog: MatDialog,private sanitizer: DomSanitizer){

  }

  ngOnInit(): void {
    this.loader.open();
      this.web.get(this.web.HOST + "/config/key/" + window.location.host).subscribe(response =>{
        this.web.get(this.web.HOST + "/ecommerce/service/" + response.result.value_config).subscribe(service =>{

          localStorage.setItem("partner",service.result.partner_id);
          this.service = service.result.id;
          this.partner = service.result.partner_id;
          // CARGAMOS LA DESCRIPCION DEL SERVICIO
          // this.description = service.result.description;
          // BUSCAMOS LOS SERVICIOS DEL SERVICIO
          this.web.get(this.web.HOST + "/ecommerce/plan/service/" + service.result.id ).subscribe(plans =>{
            // console.log(plans.result);
            this.plans = plans.result;
            this.web.get(this.web.HOST + "/partner/" + service.result.partner_id).subscribe(response =>{

              console.log(response.result)
              
              this.provinces = response.result.provinces;
              this.provinces = this.provinces.sort((a,b) => a.name > b.name ? 1 : -1);// ORDENAMOS ALFABETICAMENTE
    
              this.loader.close();
            }, err =>{
              console.log(err);
              this.loader.close();
              this.notification.showError(err);
              // this.notify.showError(err)
            });


            // this.plans = plans.result;
            this.loader.close();
          }, err =>{
            console.log(err);
            this.loader.close();
          })
        }, err =>{
          console.log(err);
          this.loader.close();
        });
      }, err =>{
        console.log(err)
      })
  }

  onPlanSelect(plan: string) {
    this.selectedPlan = plan;
  }

  showOptions(){
    this.show = true;
  }


  onProvinceChange(){
    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == this.province.id)].corregimientos;
    this.corregimientos = this.corregimientos.sort((a,b) => a.name > b.name ? 1 : -1); // ORDENAMOS ALFABETICAMENTE

  }

  send(){

    this.loader.open();

    if(this.email == ''){
      return this.notification.showInfo("Email es requerido");
    }

    if(this.selected === -1){
      return this.notification.showInfo("Debe seleccionar una suscripcion")
    }

    if(this.province === undefined){
      return this.notification.showInfo("Debe seleccionar una provincia")
    }

    if(this.corregimiento === undefined){
      return this.notification.showInfo("Debe seleccionar un corregimiento")
    }

    if(this.phone == ''){
      return this.notification.showInfo("Teléfono es requerido");
    }

    if(this.address == ''){
      return this.notification.showInfo("Dirección es requerido");
    }

    if(this.name == ''){
      return this.notification.showInfo("Nombre del titular es requerido");
    }

    if(this.year == ''){
      return this.notification.showInfo("Año de expiración es requerido");
    }
    if(this.month == ''){
      return this.notification.showInfo("Mes de expiración es requerido");
    }

    if(this.number == ''){
      return this.notification.showInfo("Número de tarjeta es requerido");
    }

    if(this.cvv == ''){
      return this.notification.showInfo("CVV es requerido");
    }

    var optionToSed = 1;
    var planToSend;
    var isOneTime = false;
    if(this.essentialType === 'pagounico'){
      planToSend = this.plans[5].id;
      if(this.selected != 0){
        if(this.selected == 1){
          optionToSed = 2;
        }else{
          optionToSed = 3;
        }
      }
      isOneTime = true;
    }else{
      planToSend = this.plans[this.selected].id;
    }

     // ESTE ES EL PROECSO PARA REALIZAR LA SUSCRIPCION
     let tdc : any = {};
     let adress : any = {};
     let send : any = {};

     tdc.numberCreditCard = this.number;
     tdc.nameHolderCreditCard = this.name;
     tdc.expMonthCreditCard = this.month;
     tdc.expYearCreditCard = this.year;
     tdc.ccv = this.cvv;
 
     adress.adress = this.address;
     //adress.province = 8;
     adress.province = +this.province.id;
     adress.corregimiento = +this.corregimiento.id;


    var data = new DataCustomerClass(this.email, this.name ,"AUTOGEN");

    var dataFM = new CreateCustomerClass(data);

    var signupData = {name_customer : this.name, lastName_customer : this.name, email_customer : this.email,status_customer : 1, code_customer : this.email, identifier_customer : this.email, dataFM : dataFM, partner : {id : this.partner }, service : {id: this.service}}

    send = {creditcard:tdc, adress:adress, customer: "-1", option: optionToSed , scope : "-1", phone : this.phone, plan : planToSend, service: this.service, param : 10, oneTime : isOneTime, signupData : signupData};
    
    // console.log(send);

    this.web.send(send,this.web.HOST + "/suscription").subscribe(response=>{

      // SUSCRIPCION PROCESADA CORRECAMENTE

      this.loader.close();
     if(response.htmlFormData){ // EN CASO DE QUE SEA 3DS
      
      this.id_3dshasplan = response.id;

       let dialogRef: MatDialogRef<any> = this.dialog.open(FacPopupComponent, {
        width: '50%',
        disableClose: true,
        data: { form: this.sanitizer.bypassSecurityTrustHtml(response.htmlFormData), id: response.id }
      })
      dialogRef.afterClosed()
        .subscribe(res => {
          this.myLoadEvent();
        })
      
     }else{
       this.notification.showError("Error procesando la Suscripción, contactar con el administrador");
     }

    }, (err) =>{
      // OCURRIO ERROR CON LA SUSCRIPCION
      console.log(err);
      this.loader.close();
      this.notification.showError(err);
      
      
    }) ; 

  }

  myLoadEvent(){

    this.loader.open();

    this.web.get(this.web.HOST + "/hasplan/" + this.id_3dshasplan).subscribe(chp =>{

      if(chp.result.status_customerPlan  != 4){
         if(chp.result.status_customerPlan == 1 ){ // ESTO ES QUE SE COBRO Y TODO BIEN CON LA SUSCRIPCION ESTA ACTIVA

           this.loader.close();
           this.notification.showSuccess("Su suscripción se ha procesado exitosamente.");
           window.location.reload();
           

       }else{
        this.loader.close();
        this.notification.showError("El registro del cliente ha fallado, intente de nuevo por favor.");
 
       }
       
      }
      
    })
   }
}
